<template>
    <div>
      <b-overlay :show="showOverlay" rounded="sm">
        <b-card no-body>
          <div class="card-header">
            <!-- Title & SubTitle -->
            <div>
              <b-card-title></b-card-title>
              <b-card-sub-title></b-card-sub-title>
            </div>
  
            <!-- Card Actions -->
            <div class="heading-elements">
              <ul class="list-inline mb-0">
                <li></li>
                <li></li>
              </ul>
            </div>
          </div> 
  
          <!-- Card Body  -->
          <b-card-body>
            <ag-grid-vue
              style="width: 100%; height: 600px"
              class="ag-theme-alpine"
              :columnDefs="columnDefs"
              :rowData="rowData"
              :pinnedTopRowData="pinnedTopRows"
            ></ag-grid-vue>
          </b-card-body>
        </b-card>
  

  
        <bruna-element-loader :dataUrl="comparePredictionsMetrics">
        </bruna-element-loader>
  


        <div>
          <b-form-select v-model="selected" :options="options" @change="metricChange"></b-form-select>
        </div>

        <bruna-element-loader :dataUrl="comparePredictionsMetrics2" loadingStrategy="ALWAYS">
        </bruna-element-loader>


        <template #overlay>
          <div class="text-center">
            <b-spinner label="Loading..." />
            <p id="cancel-label">Cargando Resultados...</p>
          </div>
        </template>
      </b-overlay>

      <b-button :to="{ name: 'canias-historico-predicciones'}"
                style="!important; float:right;"
      >
        {{ $t("Volver a historico predicciones") }} 
      </b-button>


    </div>
  </template> 
    
    <script>
  
  
  import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'
  
  import { AgGridVue } from "ag-grid-vue";
  
  import axios from '@axios'
  
  import
  {
    BTabs,
    BTab,
    BOverlay,
    BSpinner
  } from "bootstrap-vue";
  
  var qs = require('qs');
  import useApiServices from '@/services/useApiServices.js';
  
  
  export default {
    data()
    {
      let ids = this.$route.query.ids;
  
      console.log(ids)
  
      let query = qs.stringify({ run_ids: ids })
  
      console.log(query)
  
  
  
  
      return {
        showOverlay: false,
        comparePredictionsMetrics: useApiServices.comparePredictions.comparePredictionsMetrics + "?" + query,
        comparePredictionsMetrics2: useApiServices.comparePredictions.comparePredictionsMetrics2 + "?" + query,
  
        columnDefs: [],
        rowData: [],
        pinnedTopRows: [],

        selected: 'horas_sol_por_dia_final',
        options: [
          { value: null, text: 'Selecciona una métrica' },
          { value: 'horas_sol_por_dia_final', text: 'horas_sol_por_dia_final' },
          { value: 'ar_por_dia_final', text: 'ar_por_dia_final' },
          { value: 'art_por_dia_final', text: 'art_por_dia_final' },
          { value: 'hum_por_dia_final', text: 'hum_por_dia_final' },
          { value: 'pol_por_dia_final', text: 'pol_por_dia_final' },
          { value: 'brix_por_dia_final', text: 'brix_por_dia_final' },
          { value: 'fibra_por_dia_final', text: 'fibra_por_dia_final' },
          { value: 'pureza_por_dia_final', text: 'pureza_por_dia_final' },
          { value: 'e_solar_por_dia_final', text: 'e_solar_por_dia_final' },
          { value: 'temp_max_por_dia_final', text: 'temp_max_por_dia_final' },
          { value: 'temp_min_por_dia_final', text: 'temp_min_por_dia_final' },
          { value: 'm3_por_ha_por_dia_final', text: 'm3_por_ha_por_dia_final' },
          { value: 'veloc_max_por_dia_final', text: 'veloc_max_por_dia_final' },
          { value: 'K_por_area_por_dia_final', text: 'K_por_area_por_dia_final' },
          { value: 'N_por_area_por_dia_final', text: 'N_por_area_por_dia_final' },
          { value: 'P_por_area_por_dia_final', text: 'P_por_area_por_dia_final' },
          { value: 'diftermico_por_dia_final', text: 'diftermico_por_dia_final' },
          { value: 'temp_media_por_dia_final', text: 'temp_media_por_dia_final' },
          { value: 'evapo_trans_por_dia_final', text: 'evapo_trans_por_dia_final' },
          { value: 'hum_rel_avg_por_dia_final', text: 'hum_rel_avg_por_dia_final' },
          { value: 'precipitacion_por_dia_final', text: 'precipitacion_por_dia_final' },
          { value: 'hum_rel_maxima_por_dia_final', text: 'hum_rel_maxima_por_dia_final' },
          { value: 'hum_rel_minima_por_dia_final', text: 'hum_rel_minima_por_dia_final' },
          { value: 'area_por_coef_unif_por_dia_final', text: 'area_por_coef_unif_por_dia_final' },
        ]
  
  
      };
    },
  
    mounted()
    {
  
      let ids = this.$route.query.ids;
  
      console.log(ids)
  
      let query = qs.stringify({ run_ids: ids })
  
      console.log(query)
  
  
  
      axios.request({
        method: "get",
        url: useApiServices.comparePredictions.comparePredictionsMetricsTable + "?" + query,
        headers: {
          Accept: "application/json",
        },
      })
        .then((response) =>
        {
  
          this.columnDefs = response.data.columnDefs;
          this.rowData = response.data.rowData;
          this.pinnedTopRows = response.data.pinnedTopRows;
  
        })
  
  
    },
  
    created()
    {
  
  
  
  
    },
  
    components: {
      BTabs,
      BTab,
      BOverlay,
      BSpinner,
      BrunaElementLoader,
      AgGridVue
  
  
  
    },
  
    methods: {
  
      metricChange(value)
      {
        let ids = this.$route.query.ids;
  
        let query = qs.stringify({ run_ids: ids, metrica: value })

        this.comparePredictionsMetrics2 =  useApiServices.comparePredictions.comparePredictionsMetrics2 + "?" + query



      },
  
    },
  
  };
    </script>
  
  <style lang="scss" scoped>
  @import "~ag-grid-community/dist/styles/ag-grid.css";
  //@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
  @import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
  </style>
    
  <style >
  .btn-padding {
    margin-right: 48px;
  }
  
  .m-estadisticas {
    margin-left: 20px;
    margin-bottom: 1%;
    margin-top: 1%;
  }
  .icon-plan {
    padding-top: 5%;
  }


  .ag-row-pinned {
  background-color: #F0E68C !important;
}


  </style>
    
  
  <style>
  div.ag-watermark {
      display: none !important;
  }
  
  
  </style>
  